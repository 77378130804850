import styled from "styled-components"
import { baseGridStyles } from "./base"
import breakpoints from "./breakpoints"
import { Section } from "../styledComponents/section"



const TestimonialSection = styled(Section)`
  ${baseGridStyles}
  section {
    padding: 0;
  }
  p{
    margin: 0;
  }
`

const TestimonialSectionContent = styled.article`
  grid-column-start: 1;
  grid-column-end: 5;
  
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column-start: 2;
    grid-column-end: 6;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column-start: 3;
    grid-column-end: 11;
    text-align: center;
  }
`

export {
    TestimonialSection,
    TestimonialSectionContent
}