import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import parse, { domToReact } from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { OverviewIntro, LearningBlock, BackgroundColourBlock } from "../styledComponents/overView"
import { 
  ProgramSection, 
  ProgramOverview,
  ProgramBenefits,
  ProgramLectureHeader,
  ProgramLectureList,
  ProgramLectureListItem } from '../styledComponents/program'
import { StyledButton, StyledCloseModalButton } from "../styledComponents/button"
import { ModalProvider, BaseModalBackground } from "styled-react-modal";
import {StyledModal } from "../styledComponents/modal"
import HeroBanner from "../components/HeroBanner"
import Icon from "../components/icon"
import { HeroDescription, HeroText, HeroHeader } from "../styledComponents/heroBanner"  
import EventList from "../components/eventList";
import {
  SectionHeading, SectionLede
} from "../styledComponents/section"
import { StyledBlockQuote } from "../styledComponents/base"
import {

  TestimonialSection,
  TestimonialSectionContent
} from "../styledComponents/testimonial"

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

  function FancyModalButton(data) {
    const [isOpen, setIsOpen] = useState(false);
    const [opacity, setOpacity] = useState(0);
  
    function toggleModal(e) {
      setOpacity(0);
      setIsOpen(!isOpen);
    }
  
    function afterOpen() {
      setTimeout(() => {
        setOpacity(1);
      }, 100);
    }
  
    function beforeClose() {
      return new Promise((resolve) => {
        setOpacity(0);
        setTimeout(resolve, 300);
      });
    }
  
    return (
      <>   
        <StyledButton onClick={toggleModal}>Learn more</StyledButton>
        <StyledModal
          isOpen={isOpen}
          afterOpen={afterOpen}
          beforeClose={beforeClose}
          onBackgroundClick={toggleModal}
          onEscapeKeydown={toggleModal}
          opacity={opacity}
          backgroundProps={{ opacity }}
          className="index-modal"
        >
        <StyledCloseModalButton onClick={toggleModal}>
          <Icon name="cross" />
        </StyledCloseModalButton>
          {parse(data.data.programContent)}
        </StyledModal>
      </>
    );
  }
  
  const FadingBackground = styled(BaseModalBackground)`
    opacity: ${(props) => props.opacity};
    transition: all 0.3s ease-in-out;
  `;


const Program = ({ data }) => {

  return (
    <>
    <Layout hasHero>

      <SEO title={data.wpPage.title} />

      <HeroBanner image="/level-1.jpg" backgroundPosition="99% 0">
          <HeroText>
            <HeroHeader>
                {data.wpPage.heroSection.kaarmibeingHeroHeading}
            </HeroHeader>
            <HeroDescription>{data.wpPage.heroSection.kaarmibeingHeroLedeText}</HeroDescription>
            <AnchorLink to="/level-1#overview" stripHash title="Find out how the KaarmiBeing team can help you with your health and wellbeing">Find out more</AnchorLink>
          </HeroText>
      </HeroBanner>

      <ProgramSection id="overview">
        <ProgramOverview>
        {parse(data.wpPage.content)}
        </ProgramOverview>
      </ProgramSection>

      <ProgramSection>
        <ProgramLectureHeader>We will address the following main key areas of life</ProgramLectureHeader>
        <ProgramLectureList>
          {data.wpPage.OurProgram.programData.map(
            (data, index) => {
              const lectureImage = {
                imageData: data.svg.localFile.childImageSharp.gatsbyImageData,
              }
            return (
              <ProgramLectureListItem>
                <GatsbyImage image={lectureImage.imageData} alt="" />
                <h3>{data.programHeader}</h3>
                <p>{data.programLede}</p>
                <ModalProvider backgroundComponent={FadingBackground}>
                    <FancyModalButton data={data}/>
                </ModalProvider>
              </ProgramLectureListItem>
            )}
          )}
        </ProgramLectureList>
      </ProgramSection>

      <BackgroundColourBlock number="1">
          <LearningBlock>
              <figure>
              <img src="/static/09533594904da2e05ab5996d1d895a7e/stress.svg" alt="" />
              </figure>
              <div className="overviewWrapper">
                  <h2>You will also learn:</h2>
                  <ul>
                    <li>Practical exercises and techniques</li>
                    <li>Specific meditation postures</li>
                    <li>Specific breathing exercises</li>
                    <li>How to unite your mind, speech and body for an optimum focused state of mind</li>
                    <li>How to calm the mind and reset the nervous system</li>
                    <li>We will host live interactive discussions with in depth instructions to enable each person to gain a true understanding of this knowledge</li>
                    <li>You will learn how to integrate all of the above in your daily life</li>
                  </ul>
              </div>
              <ProgramBenefits>
              <p>We can use breathing and meditation techniques to achieve different goals in life. The KaarmiBeing advanced meditation and breathing techniques are specifically focused on achieving the following benefits:</p>
              <ul>
                    <li>Increased vitality</li>
                    <li>Overcome a scattered mind</li>
                    <li>Increased concentration and ability to focus</li>
                    <li>Overcoming intrusive thoughts</li>
                    <li>Overcoming the desires that are influenced by having low vitality and a scattered mind</li>
                  </ul>
              </ProgramBenefits>
          </LearningBlock>
        </BackgroundColourBlock>

        <TestimonialSection>
          <Heading level={1}>
            Testimonials
          </Heading>
          <SectionLede>Here are some of our latest students tesimonials.</SectionLede>
          <TestimonialSectionContent>
            <StyledBlockQuote>
              <Icon name="singlequote" />
              <p>I very much enjoyed the introductory workshop which was full of practical and useful techniques to deal with stress.  </p><p>Amir explained how to use the breath to let go of intrusive thoughts and stress and he very quickly got us practising a technique to stay calm and peaceful.  We then when further with an exercised to help remain focused, useful if you are deeply involved with what ever you are doing and remain present.  The beauty of the techniques is that you can do the exercises in minutes and on the go!  I highly recommend this course if you are struggling to stay calm and focused.</p>
              <cite>Tyroon Win, Legal Practice Manager</cite>
            </StyledBlockQuote>
            <StyledBlockQuote>
              <Icon name="singlequote" />
              <p>It was a fascinating course. The techniques were easy, effective, and can be easily applied to daily life. The speed of the breathing and focus techniques was a surprise to me and I was a bit skeptical that I could calm my monkey mind so quickly. Yet, these techniques were an easy switch-off button for my mind which was really amazing for me. Amir has obviously led a very interesting path in life is a great teacher with Karina.  I look forward to taking the full course!</p>
              <cite>J.B, Pharmacist, UK</cite>
            </StyledBlockQuote>
            <StyledBlockQuote>
              <Icon name="singlequote" />
              <p>I can really understand the need for this information and I thoroughly enjoyed it. It is perfect for practical day-to-day life. Learning about the personality types and 6 enemies was fantastic to know about. If we learn these techniques and understand them, life becomes so much easier. It changes our interactions with other people. Understanding your own personality and how to understand where others come from too is the way to reflect positive relationships around us.</p>
 
              <p>The practical breathing is fantastic. For me, it really moved me to another level because I felt one in my body and with myself. It was such a powerful feeling. It was a one-minute exercise and I went somewhere. I didn't want to open my eyes and come back to reality. Time really stopped for me in those exercises.  I didn't want to come out of it.  I was enjoying the energy of the class and Amir has a really lovely energy teaching with Karina.</p>
              <cite>Yasmin Z, NHS Nurse</cite>
            </StyledBlockQuote>

            <StyledBlockQuote>
              <Icon name="singlequote" />
              <p>I attended the taster workshop for KaarmiBeing and just had to say how much I enjoyed it! The breathing techniques were so powerful and a wonderful tool for calming stress and anxiety in everyday life. They are definitely something I will be using going forward! Karina and Amir make a great team and some of the concepts they discussed on the workshop were really interesting and insightful. I highly highly recommend attending this workshop and can't wait for the full course!!!</p>
              <cite>C. Greene,  Body Confidence Coach and singer</cite>
            </StyledBlockQuote>


            
          </TestimonialSectionContent>
        </TestimonialSection>

        <EventList data={data} page="Level 1"/>

        <ProgramSection>
          <ProgramOverview>
          <p>In order to maximise your experience we have integrated the practical exercises above into one program. </p>
          <p>We know that people are very busy and that time is often limited. Therefore we have created a program structure that supports maximum growth in a practical and realistic timeframe for you. </p>
          <p>The KaarmiBeing techniques are easy to learn and you will be able to use them in your daily life with ease.</p>
          </ProgramOverview>
        </ProgramSection>

    </Layout>
    </>
  )
}

export default Program

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "level-1" }) {
      title
      content
      heroSection {
        kaarmibeingHeroBackground {
            uri
            localFile {
              publicURL
            }
        }
        kaarmibeingHeroHeading
        kaarmibeingHeroLedeText
        kaarmibeingHeroPrimaryCta {
          title
          url
        }
      }
      OurProgram {
        programData {
          programContent
          programHeader
          programLede
          svg {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }

      eventsData {
        events {
          date
          time
          timezone
          fieldGroupName
          location
          price
          shortDescription
        }
      }
    }
  }
`
